:focus {
	outline: none;
}
.modal_create_edit_note_modal-content {
	width: 100%;
	max-width: 1000px;
	background-color: #ffffff;
	margin: 5% auto;
	padding: 20px;
	border: 1px solid #888;
	border-radius: 10px;
}

.modal_create_edit_note_keyboardAvoidView {
	flex: 1;
}
.modal_create_edit_note_backgroundView {
	flex: 1;
}
.modal_create_edit_note_container {
	flex: 1;
	background-color: rgb(255, 255, 255);
	width: 100%;
	border: 0px solid rgb(247, 247, 247);
}
.modal_create_edit_note_editor {
	min-height: 200px;
}
.modal_create_edit_note_toolbar {
}
.modal_create_edit_note_titleinput {
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 24px;
	font-weight: bold;
	margin-top: 0px;
	color: black;
	width: 100%;
	box-sizing: border-box;
	resize: none;
	border: 0px solid #ccc;
	height: 40px;
}
.modal_create_edit_note_contentinput {
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 20px;
	min-height: 200px;
	margin-top: 0px;
	color: black;
	width: 100%;
	box-sizing: border-box;
	resize: vertical;
	border: 0px solid #ccc;
}
.modal_create_edit_note_tagcategory_view {
	border-top-width: 0px;
	min-height: 40px;
	align-items: flex-start;
	flex-direction: row;
	width: 100%;
}
.modal_create_edit_note_tags_view {
	min-height: 30px;
	flex: 1;
	min-width: 0px;
	flex-grow: 1;
	padding-left: 8px;
	padding-right: 8px;
}
.modal_create_edit_note_category_view {
	min-height: 30px;
	text-align: right;
}
.modal_create_edit_note_tagcategory_view_item {
}
.modal_create_edit_note_category_view_items_button {
	padding-left: 8px;
	padding-right: 8px;
}
.modal_create_edit_note_category_view_items_button_view {
	align-items: center;
	flex-direction: row;
	justify-content: flex-end;
}
.modal_create_edit_note_tagscontainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 10px;
}
.modal_create_edit_note_tag {
	display: flex;
	background-color: #8181812d;
	border-radius: 20px;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 12px;
	padding-right: 10px;
	margin-right: 5px;
	margin-bottom: 0px;
	flex-direction: row;
	align-items: center;
	margin-top: 4px;
}
.modal_create_edit_note_tagText_hash {
	color: #5e5e5e;
	font-size: 18px;
	font-weight: 600;
	margin-right: 2px;
	margin-top: -2px;
}
.modal_create_edit_note_tagText_tag {
	color: #5e5e5e;
	font-size: 16px;
	margin-right: 9px;
	font-weight: 600;
	margin-top: -2px;
}
.modal_create_edit_note_tagDelete {
	border-radius: 10px;
	padding: 5px;
}
.modal_create_edit_note_tagDeleteText {
	color: #5e5e5e;
	width: 10px;
	height: 10px;
}
.modal_create_edit_note_input {
	display: flex;
	flex: 1 1;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 20px;
	font-size: 16px;
	color: black;
	border: 1px solid #e4e4e400;
	margin-top: 4px;
	max-width: 200px;
}

.modal_create_edit_note_modal-content-title {
	color: #585858;
	font-size: 24px;
	margin-top: 0px;
	margin-bottom: 0px;
}

.modal_create_edit_note_modal-content-subtitle {
	color: #feb400;
	font-size: 22px;
}

.modal_create_edit_note_modal-content-text {
	color: white;
	font-size: 16px;
}

.modal_create_edit_note_modal-content-text-unmargered {
	color: white;
	font-size: 16px;
	margin: 0px;
}

.modal_create_edit_note_modal-content-img {
	max-width: 100%;
}

.modal_create_edit_note_close {
	color: rgb(30 30 30);
	float: right;
	font-size: 28px;
	font-weight: bold;
	top: 1px;
	margin-top: -16px;
}

.modal_create_edit_note_close:hover,
.modal_create_edit_note_close:focus {
	color: rgb(117, 117, 117);
	text-decoration: none;
	cursor: pointer;
}

.modal_create_edit_note_highlighted-text {
	color: #0ba7ff;
}

.modal_create_edit_note_modal-button {
	color: black;
	background-color: rgb(209 209 209);
	padding: 10px;
	border-width: 0px;
	font-weight: bold;
	cursor: pointer;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: 1em;
	border-radius: 4px;
}
.modal_create_edit_note_modal-button:hover {
	background-color: rgb(146, 146, 146);
}
.modal_create_edit_note_modal-button-secondary {
	color: rgb(0, 0, 0);
	background-color: rgb(226, 226, 226);
	padding: 6px;
	border-width: 0px;
	border-radius: 4px;
	cursor: pointer;
}
.modal_create_edit_note_modal-button-secondary:hover {
	background-color: rgb(194, 194, 194);
}

.modal_create_edit_note_bottom_buttons_container {
	border-top: 1px solid rgb(228, 228, 228);
	padding-top: 10px;
	margin-top: 10px;
	text-align-last: end;
	justify-content: end;
	vertical-align: middle;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.modal_create_edit_note_modal-btn-image {
	width: 20px;
	height: 20px;
}

.modal_create_edit_note_modal-btn-options-container {
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center;
}

.modal_create_edit_note_modal-btn-date {
	margin-left: 10px;
	font-size: 13px;
	font-weight: 400;
	height: auto;
	color: grey;
}

.modal_create_edit_note_modal_container_select_category {
	background-color: #08080863;
	position: absolute;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 28;
}

.editorClassName {
	min-height: 200px;
	margin-bottom: 2em;
}
