.select_category_modal_option {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px;
	background-color: #88888810;
	border-radius: 10px;
	margin-bottom: 2px;
	padding: 6px;
}

.select_category_modal_optionAddNew {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 10px;
	border-radius: 10px;
	margin-bottom: 2px;
	padding: 6px;
}

.select_category_modal_optionText {
	color: black;
}

.select_category_modal_optionTextAddNew {
	color: #0ba7ff;
}

.select_category_modal_optionTextAddNew-button {
	margin-top: 20px !important;
}

.select_category_modal_optionsScrollview {
	height: 50%;
	margin-bottom: 10px;
}

.select_category_modal_optionsScrollviewContainer {
	margin-top: 10px;
	margin-bottom: 25px;
}

.select_category_modal_colorDot {
	border-radius: 3px;
	width: 8px;
	height: 8px;
	border: 1px solid #8181812d;
	margin-right: 10px;
}

.select_category_modal_modal-content {
	width: 100%;
	max-width: 1000px;
	background-color: #ffffff;
	margin: 5% auto;
	padding: 20px;
	border: 1px solid #888;
	border-radius: 10px;
}

.select_category_modal_keyboardAvoidView {
	flex: 1;
}
.select_category_modal_backgroundView {
	flex: 1;
}

.select_category_modal_modal-content-title {
	color: #585858;
	font-size: 24px;
	margin-top: 0px;
	margin-bottom: 0px;
}

.select_category_modal_modal-content-subtitle {
	color: #0ba7ff;
	font-size: 22px;
}

.select_category_modal_modal-content-text {
	color: white;
	font-size: 16px;
}

.select_category_modal_modal-content-text-unmargered {
	color: white;
	font-size: 16px;
	margin: 0px;
}

.select_category_modal_modal-content-img {
	max-width: 100%;
}

.select_category_modal_close {
	color: rgb(30 30 30);
	float: right;
	font-size: 28px;
	font-weight: bold;
	top: 1px;
	margin-top: -16px;
}

.select_category_modal_close:hover,
.select_category_modal_close:focus {
	color: rgb(117, 117, 117);
	text-decoration: none;
	cursor: pointer;
}

.select_category_modal_highlighted-text {
	color: #0ba7ff;
}

.select_category_modal_modal-button {
	color: white;
	background-color: #0ba7ff;
	padding: 10px;
	border-width: 0px;
	font-weight: bold;
	cursor: pointer;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 4px;
	margin-left: 50px;
}
.select_category_modal_modal-button:hover {
	background-color: #028fe0 !important;
}
.select_category_modal_modal-button-secondary {
	color: rgb(0, 0, 0);
	background-color: rgb(226, 226, 226);
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	border-width: 0px;
	border-radius: 4px;
	cursor: pointer;
}
.select_category_modal_modal-button-secondary:hover {
	background-color: rgb(194, 194, 194);
}

.select_category_modal_bottom_buttons_container {
	border-top: 1px solid rgb(228, 228, 228);
	padding-top: 20px;
	margin-top: 10px;
	text-align-last: end;
	justify-content: end;
	vertical-align: middle;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.select_category_modal_modal-btn-image {
	width: 20px;
	height: 20px;
}

.select_category_modal_modal-btn-options-container {
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center;
}
.select_category_modal_modalContainer {
	width: 100%;
	height: 100%;
}
.select_category_modal_modalCard {
	background-color: white;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: 0 auto;
	transform: translate(-50%, -50%);
	padding: 20px;
	border-radius: 10px;
}

.select_category_modal_containers {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 29;
}
.select_category_modal_modalTitle {
	color: rgb(85, 85, 85);
}
.select_category_modal_option {
	background-color: #8080802b !important;
	width: 100%;
	margin-bottom: 4px !important;
}
.select_category_modal_option:hover {
	background-color: #6e6e6e49 !important;
}
.select_category_modal_option_container {
	display: flex;
	width: 100%;
	height: 100%;
	padding: 6px;
	border-radius: 34px;
	align-items: center;
}
