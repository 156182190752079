.category_drawer_option_container {
	flex-direction: row;
	align-items: center;
	padding: 7px;
	/* margin-right: 10px; */
	/* margin-left: 10px; */
	margin-top: 2px;
	margin-bottom: 2px;
	display: flex;
	/* background-color: #efefef; */
	border-radius: 8px;
}
.category_drawer_option_colorDot {
	height: 10px;
	width: 10px;
	border-radius: 10px;
	margin-right: 10px;
}
.category_drawer_option_containerName {
	flex: 1;
	text-align: left;
}
.category_drawer_option_name {
	font-size: 14px;
}
.category_drawer_option_3dots {
	width: 20px;
	height: 20px;
	background-color: rgba(226, 226, 226, 0.164) !important;
	border-radius: 20px;
}
.category_drawer_option_3dots_img {
	width: 20px;
	height: 20px;
}

.category_drawer_option_dropdown-container {
	position: relative;
	display: inline-block;
}

.category_drawer_option_dropdown-button {
	padding: 8px 12px;
	border: none;
	background-color: #0ba7ff;
	color: white;
	cursor: pointer;
}

.category_drawer_option_options-list {
	position: absolute;
	transform: translateX(22px);
	background-color: white;
	border: 1px solid #ccc;
	list-style: none;
	padding: 0;
	margin: 0;
	z-index: 1;
	border-radius: 6px;
}

.category_drawer_option_options-list li {
	padding: 8px 12px;
	cursor: pointer;
}

.category_drawer_option_options-list li:hover {
	background-color: #f0f0f0;
}
