.profile_modal_scrollView {
	background-color: white;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: 0 auto;
	transform: translate(-50%, -50%);
	padding: 20px;
	border-radius: 10px;
	text-align: center;
}

.profile_modal_containers {
	background-color: #00000047;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 3;
}
.profile_modal_modalTitle {
	color: rgb(85, 85, 85);
}
.profile_modal_option {
	background-color: #8080802b !important;
	width: 100%;
	margin-bottom: 4px !important;
}
.profile_modal_option:hover {
	background-color: #6e6e6e49 !important;
}
.profile_modal_option_container {
	display: flex;
	width: 100%;
	height: 100%;
	padding: 6px;
	border-radius: 34px;
	align-items: center;
}
.profile_modal_textInputLeft {
	width: 100%;
	border: 1px solid #cdcdcd;
	border-radius: 4px;
	height: 30px;
}
.profile_modal_label {
	text-align: left;
	width: 100%;
	display: block;
	margin-top: 13px;
	margin-bottom: 6px;
}
.profile_modal_modalInputContainer {
}
.profile_modal_profileImage {
	width: 100%;
	height: 200px;
	object-fit: contain;
}
.profile_modal_profileName {
	width: 100%;
	display: block;
	text-align: center;
	font-size: 24px;
}

.profile_modal_profileSurname {
	width: 100%;
	display: block;
	text-align: center;
	font-size: 17px;
	font-weight: 600;
}
.profile_modal_profileEmail {
	width: 100%;
	display: block;
	text-align: center;
	color: #00b6ff;
	font-weight: 500;
	margin-top: 10px;
}
.profile_modal_btn_danger {
	background-color: #e7e7e7 !important;
	color: red;
	padding-left: 20px !important;
	padding-right: 20px !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	border-radius: 10px !important;
	margin-top: 2em !important;
}
.profile_modal_btn_danger:hover {
	background-color: #cccccc !important;
}
