.footer {
	text-align: center;
	display: block;
	padding-top: 4px;
	padding-bottom: 8px;
	width: 100%;
	bottom: 0px;
	background-color: #dedede;
}

.footer span {
	color: #7d7d7d;
}

.footer span a {
	text-decoration: none;
	color: #0ba7ff;
}
