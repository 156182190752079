.view_auth_login_container {
	background-color: rgb(255 255 255);
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
	border-radius: 14px;
	overflow: hidden;
}

.view_auth_login-signup_div {
	width: 100%;
}

.view_auth_tab-button {
	width: 50%;
	padding: 1em;
	background-color: rgb(255 255 255);
	color: #0ba7ff;
	border-width: 0px;
	font-weight: bold;
	cursor: pointer;
	border-bottom: 1px solid #c7c7c7;
}
.view_auth_tab-button:hover {
	background-color: rgb(182, 182, 182);
}

.view_auth_tab-button-active {
	color: white;
	background-color: #0ba7ff;
}
.view_auth_tab-button-active:hover {
	color: white;
	background-color: #0ba7ff;
}

.view_auth_form-title {
	color: black;
	margin-bottom: 0px;
}

.view_auth_form-input {
	display: block;
	background-color: white;
	color: black;
	padding: 0px;
	margin-top: 5px;
	width: 90%;
	margin-left: 5%;
	border: 2px solid #c3c3c3;
	padding-top: 1em;
	padding-bottom: 1em;
	text-align: center;
	border-radius: 7px;
}

.view_auth_btn-submit {
	margin-top: 1em;
	margin-bottom: 1em;
	border-width: 0px;
	background-color: #0ba7ff;
	padding: 1em;
	font-weight: bold;
	padding-left: 2em;
	padding-right: 2em;
	width: 90%;
	cursor: pointer;
	color: white;
	border-radius: 7px;
}
.view_auth_btn-submit:hover {
	background-color: #0ba7ff;
}

.view_auth_message_b_error {
	color: rgb(255, 81, 0);
	display: block;
	padding-bottom: 1em;
}

.view_auth_email_sent_message {
	color: rgb(0 0 0);
	display: block;
	padding-bottom: 1em;
	font-size: 20px;
	font-weight: 400;
}

.view_auth_forgot-password-btn {
	color: #0ba7ff;
	font-size: 20;
	font-weight: 400;
	display: block;
	margin-top: 2px;
	margin-bottom: 1em;
	text-align: right;
	padding-right: 20px;
	cursor: pointer;
}

.view_auth_form-img {
	width: 80%;
	margin-top: 1em;
}
.view_auth_form-img-main {
	width: 60%;
	margin-top: 1em;
	margin-bottom: 1em;
}
.view_auth_form-img_slogan {
	width: 50%;
}
.view_auth_login-div {
	padding: 2em;
}

.view_auth_form-subtitle {
	font-size: 20px;
	font-weight: 300;
	margin-top: 0px;
}
