.category_view_items_button_view_item {
	width: auto;
	align-self: flex-start;
	border-radius: 20px;
	margin-left: 4px;
	flex-direction: row;
	padding-top: 3px;
	padding-bottom: 3px;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
}

.category_view_items_button_view_item_text {
	width: auto;
	font-weight: 400;
}

.colorDot {
	border-radius: 3px;
	width: 8px;
	height: 8px;
	border-width: 1px;
	border-color: #8181812d;
}

.containerName {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 10px;
	border-bottom-color: #0ba7ff;
}

.name {
	font-size: 16px;
	color: #5e5e5e;
}

.colorDot {
	border-radius: 3px;
	width: 8px;
	height: 8px;
	border: 1px solid #8181812d;
}
