.tag_drawer_option_container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	margin: 2px 10px;
	background-color: #8181812d;
	border-radius: 20px;
	padding: 3px 8px;
	margin-right: 0;
	margin-bottom: 5px;
	margin-top: 5px;
}

.tag_drawer_option_containerName {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.tag_drawer_option_name {
	font-size: 16px;
	color: #5e5e5e;
	margin-right: 5px;
}

.tag_drawer_option_hastagsymbol {
	margin-right: 4px;
	color: #5e5e5e;
	font-size: 20px;
	font-weight: 100;
}
