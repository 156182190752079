.modal {
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
	background-color: #000000;
	margin: 5% auto;
	padding: 20px;
	border: 1px solid #888;
	width: 80%;
}

.modal-content-title {
	color: #0ba7ff;
	font-size: 28px;
}

.modal-content-subtitle {
	color: #0ba7ff;
	font-size: 22px;
}

.modal-content-text {
	color: white;
	font-size: 16px;
}

.modal-content-text-unmargered {
	color: white;
	font-size: 16px;
	margin: 0px;
}

.modal-content-img {
	max-width: 100%;
}

.close {
	color: rgb(221, 221, 221);
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: rgb(117, 117, 117);
	text-decoration: none;
	cursor: pointer;
}

.highlighted-text {
	color: #0ba7ff;
}
