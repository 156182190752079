.navbar {
	text-align: center;
	border-top: 3px solid black;
	padding-top: 4px;
	padding-bottom: 8px;
	width: 100%;
	background-color: black;
}

.navbar span {
	color: white;
}

.navbar span a {
	text-decoration: none;
}

.navbar_div {
	padding-top: 1em;
	padding-bottom: 1em;
	text-align: right;
	padding-right: 10%;
}

.navbar_button {
	background-color: #c9c9c9;
	color: #020202;
	cursor: pointer;
	position: relative;
	padding: 9px;
	font-weight: bold;
	margin-left: 2px;
	border-width: 0px;
	border-radius: 4px;
}

.navbar_button:hover {
	background-color: #00c7ff;
	color: rgb(255, 255, 255);
}

.navbar_text {
	background-color: #00c7ff;
	color: white;
	cursor: pointer;
	position: relative;
	padding: 9px;
	font-weight: bold;
	margin-left: 16px;
	margin-right: -2px;
	border-width: 0px;
}

.navbar_profile_icon {
	width: 36px;
	height: 36px;
	margin-right: 5px;
}

.navbar_profile_name {
	font-size: 18px;
	font-weight: 600;
}

.navbar_button_styled {
	background-color: #ffffff;
	color: #757575;
	cursor: pointer;
	position: relative;
	padding: 2px;
	padding-left: 4px;
	padding-right: 12px;
	font-weight: bold;
	margin-left: 16px;
	border-width: 0px;
	display: inline-flex;
	align-items: center;
	border-radius: 6px;
}

.navbar_button_styled:hover {
	background-color: #f0f0f0;
}
