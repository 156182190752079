.view_notes_container {
	flex: 1;
	background-color: rgb(255, 255, 255);
}
.view_notes_card_buttoned {
	display: inline-block;
	width: 33.2%;
	float: left !important;
}

.view_notes_card_buttoned_complete {
	width: 100% !important;
	float: left !important;
}

@media (min-width: 1200px) {
	.view_notes_card_buttoned {
		width: 33.2%;
	}
}
@media (min-width: 600px) and (max-width: 1199px) {
	.view_notes_card_buttoned {
		width: 50%;
	}
}
@media (max-width: 599px) {
	.view_notes_card_buttoned {
		width: 100%;
	}
}

.view_notes_categoryname_oncarousel_view {
	margin-left: 20px;
	padding-top: 2px;
	padding-bottom: 2px;
	padding-left: 16px;
	padding-right: 16px;
	border-radius: 20px;
	margin-top: 20px;
	align-self: flex-start;
	display: flex;
	width: fit-content;
}
.view_notes_categoryname_oncarousel_text {
	color: #5e5e5e;
	font-weight: 600;
	font-size: 18px;
	align-self: center;
}
.view_notes_emptyImagenContainer {
	align-items: center;
	margin-left: 20px;
	margin-right: 20px;
	margin-top: 20px;
	display: block;
	padding-top: 4em;
	padding-bottom: 7em;
}
.view_notes_emptyImagen {
	width: 300px;
	height: 300px;
	opacity: 0.5;
}
.view_notes_emptyImagenText {
	font-size: 30px;
	font-weight: 800;
	color: #cdcdcd;
	text-align: center;
	display: block;
}
.view_notes_emptyImagenSubText {
	font-size: 28px;
	font-weight: 300;
	color: #cdcdcd;
	text-align: center;
}
.view_notes_emptyImagenSubTextImage {
	width: 26px;
	height: 26px;
	margin-left: 4px;
	margin-right: 4px;
}
.view_notes_empty_button_text_description {
	align-content: center;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
	color: #cdcdcd;
}

.view_notes_listHeaderTitle {
	font-size: 34px;
	color: black;
	padding-left: 20px;
	padding-bottom: 8px;
	font-weight: bold;
	width: 100%;
	margin-top: 10px;
}

.notes_main_container {
	background-color: rgb(255, 255, 255);
	padding: 10px;
	display: flex;
	border-radius: 10px;
	margin-left: 2%;
	margin-right: 2%;
}

.notes_main_container_drawer {
	width: 20%;
}
.view_notes_container_notes {
	width: 80%;
}

.view_notes_global_container {
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
}
.view_notes_left_container {
	align-self: flex-start;
	width: 80px;
}
.view_notes_global_title {
	align-self: center;
}
.view_notes_listHeaderTitle {
}
.view_notes_global_buttons_container {
	align-self: flex-end;
}
.view_notes_global_buttons_container_inside {
}
.view_notes_global_buttons_container_secondary {
	background-color: rgb(245 245 245);
	align-items: center;
	display: flex;
	border-radius: 10px;
	margin-left: 10px;
	margin-right: 10px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
}
.view_notes_global_buttons_container_secondary:hover {
	background-color: rgb(214, 214, 214);
}
.view_notes_global_buttons_container_secondary_icon {
	width: 28px;
	height: 28px;
}
.view_notes_global_buttons_container_primary {
	background-color: rgb(245 245 245);
	align-items: center;
	display: flex;
	border-radius: 10px;
	margin-left: 10px;
	margin-right: 10px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
}
.view_notes_global_buttons_container_primary:hover {
	background-color: rgb(214, 214, 214);
}
.view_notes_global_buttons_container_primary_icon {
	width: 28px;
	height: 28px;
}
.view_notes_global_buttons_container_primary_text {
	margin-left: 8px;
	color: black;
}
.view_notes_categoryname_oncarousel_view_container {
	margin-bottom: 47px;
	width: 100%;
	display: inline-block;
}
.view_notes_card_buttoned_part_container {
}
