@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.loading-spinner {
	width: 25px;
	height: 25px;
	border: 10px solid #dddddd;
	border-top: 10px solid #0ba7ff;
	border-radius: 50%;
	animation: spinner 1.5s linear infinite;
	display: flex;
}

.spinner-td-container {
	text-align: -webkit-center !important;
}

.div_spinner_container {
	text-align: center !important;
	position: relative;
	display: inline-block;
}

.spinner-container {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 1em;
	margin-top: 1em;
}
