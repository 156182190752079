.note_card_view_container {
	width: 100%;
}

.note_card_view_card {
	background-color: rgba(238, 238, 238, 0.966);
	margin: 6px;
	border-radius: 6px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	border-color: #cdcdcd;
	border-width: 1px;
	padding: 10px;
	position: relative;
}

.note_card_view_title {
	font-weight: 700;
	font-size: 18px;
	padding: 10px 20px 6px 8px;
	color: black;
}

.note_card_view_content {
	font-weight: 400;
	padding: 0 20px 6px 8px;
	color: black;
	width: auto;
	font-size: 18px;
}

.note_card_view_contentContainer {
	align-items: flex-start;
	flex-direction: row;
}

.note_card_view_categoryColor {
	border-radius: 3px;
	width: 8px;
	height: 8px;
	position: absolute;
	right: 7px;
	top: 1px;
	z-index: 1;
	margin-top: 6px;
}

.note_card_view_tags_container {
	display: flex;
	flex-direction: row;
	padding-right: 10px;
	overflow: hidden;
}

.note_card_view_card_title_and_description {
	width: 100%;
	text-align: left;
	margin-bottom: 10px;
	overflow: hidden;
}

.note_card_view_tags_and_options {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	min-height: 30px;
}
.note_card_view_options {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
}
.note_card_view_options_container {
	display: flex;
	flex-direction: row;
	padding-left: 10px;
	padding-right: 10px;
	height: 28px;
}
.note_card_view_options_modified_date {
	color: rgb(110, 110, 110);
	font-weight: 500;
	font-size: 12px;
	align-self: end;
}
.note_card_view_options_option {
	background-color: rgb(211, 211, 211);
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 4px;
	padding-bottom: 4px;
	margin-left: 4px;
	margin-right: 4px;
	border-radius: 4px;
}

.note_card_view_options_option_image {
	width: 20px;
	height: 20px;
}
