.delete_modal_colorContainer {
	margin-bottom: 20px;
}

.delete_modal_colorItem {
	width: 40px;
	height: 40px;
	margin: 4px;
	border-radius: 5px;
	border-style: solid;
	box-sizing: border-box;
}

.delete_modal_colorsPalleteContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.delete_modal_buttonDelete {
	background-color: grey;
	padding: 10px 8px;
	border-radius: 5px;
}

.delete_modal_buttonDeleteImage {
	width: 16px;
	height: 16px;
}

.delete_modal_option {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px;
	background-color: #88888810;
	border-radius: 10px;
	margin-bottom: 2px;
	padding: 6px;
}

.delete_modal_optionAddNew {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 10px;
	border-radius: 10px;
	margin-bottom: 2px;
	padding: 6px;
}

.delete_modal_optionText {
	color: black;
}

.delete_modal_optionTextAddNew {
	color: #0ba7ff;
}

.delete_modal_optionsScrollview {
	height: 50%;
	margin-bottom: 10px;
}

.delete_modal_optionsScrollviewContainer {
	margin-top: 10px;
	margin-bottom: 25px;
}

.delete_modal_colorDot {
	border-radius: 3px;
	width: 8px;
	height: 8px;
	border: 1px solid #8181812d;
	margin-right: 10px;
}

.delete_modal_modal-content {
	width: 100%;
	max-width: 1000px;
	background-color: #ffffff;
	margin: 5% auto;
	padding: 20px;
	border: 1px solid #888;
	border-radius: 10px;
}

.delete_modal_keyboardAvoidView {
	flex: 1;
}
.delete_modal_backgroundView {
	flex: 1;
}

.delete_modal_modal-content-title {
	color: #585858;
	font-size: 24px;
	margin-top: 0px;
	margin-bottom: 0px;
}

.delete_modal_modal-content-subtitle {
	color: #feb400;
	font-size: 22px;
}

.delete_modal_modal-content-text {
	color: white;
	font-size: 16px;
}

.delete_modal_modal-content-text-unmargered {
	color: white;
	font-size: 16px;
	margin: 0px;
}

.delete_modal_modal-content-img {
	max-width: 100%;
}
.delete_modal_close {
	color: rgb(30 30 30);
	float: right;
	font-size: 28px;
	font-weight: bold;
	top: 1px;
	margin-top: -16px;
}

.delete_modal_close:hover,
.delete_modal_close:focus {
	color: rgb(117, 117, 117);
	text-decoration: none;
	cursor: pointer;
}

.delete_modal_highlighted-text {
	color: #feb400;
}

.delete_modal_modal-button {
	color: white;
	background-color: rgb(255, 0, 0);
	padding: 10px;
	border-width: 0px;
	font-weight: bold;
	cursor: pointer;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 4px;
}
.delete_modal_modal-button:hover {
	background-color: rgb(212, 0, 0) !important;
}
.delete_modal_modal-button_container {
	margin-right: 50px !important;
}
.delete_modal_modal-button-secondary {
	color: rgb(0, 0, 0);
	background-color: rgb(226, 226, 226);
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	border-width: 0px;
	border-radius: 4px;
	cursor: pointer;
}
.delete_modal_modal-button-secondary:hover {
	background-color: rgb(194, 194, 194);
}
.delete_modal_buttonDelete {
	color: rgb(0, 0, 0);
	background-color: rgb(226, 226, 226);
	padding: 6px;
	padding-left: 12px;
	padding-right: 12px;
	border-width: 0px;
	border-radius: 4px;
	cursor: pointer;
}
.delete_modal_buttonDelete:hover {
	background-color: rgb(194, 194, 194);
}

.delete_modal_bottom_buttons_container {
	border-top: 1px solid rgb(228, 228, 228);
	padding-top: 20px;
	margin-top: 10px;
	text-align-last: end;
	justify-content: end;
	vertical-align: middle;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.delete_modal_modal-btn-image {
	width: 20px;
	height: 20px;
}

.delete_modal_modal-btn-options-container {
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center;
}
.delete_modal_modalContainer {
}
.delete_modal_modalCard {
	background-color: white;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: 0 auto;
	transform: translate(-50%, -50%);
	padding: 20px;
	border-radius: 10px;
}

.delete_modal_containers {
	background-color: #00000047;
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 31;
}
.delete_modal_modalTitle {
	color: rgb(85, 85, 85);
}
.delete_modal_option {
	background-color: #8080802b !important;
	width: 100%;
	margin-bottom: 4px !important;
}
.delete_modal_option:hover {
	background-color: #6e6e6e49 !important;
}
.delete_modal_option_container {
	display: flex;
	width: 100%;
	height: 100%;
	padding: 6px;
	border-radius: 34px;
	align-items: center;
}
.delete_modal_textInputLeft {
	width: 100%;
	border: 1px solid #cdcdcd;
	border-radius: 4px;
	height: 30px;
}
.delete_modal_label {
	text-align: left;
	width: 100%;
	display: block;
	margin-top: 13px;
	margin-bottom: 6px;
}
.delete_modal_modalInputContainer {
}
