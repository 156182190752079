.left_drawer_label {
	font-weight: 700;
	font-size: 18px;
	color: #5e5e5e;
}

.left_drawer_categoriesTitleContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-left: 10px;
	margin-top: 20px;
}

.left_drawer_rightButtonIcon {
	width: 12px;
	height: 12px;
	align-content: center;
}

.left_drawer_tagscontainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 10px;
	padding: 0 4px;
}

.left_drawer_addIconWrapper {
	background-color: #8888884d;
	border-radius: 100px;
	padding: 4px;
	padding-left: 5px;
	padding-right: 5px;
	margin-right: 15px;
}

.left_drawer_scrollView {
	min-height: 100%;
}

.left_drawer_left_drawer_categories_container {
	display: grid;
	padding: 14px;
}
